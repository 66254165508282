<template>
  <div>
    <h1 class="header">
      Invoices
    </h1>
    <el-tabs
      :value="$route.name"
      @tab-click="$route.name !== $event.name ? $router.push({ name: $event.name }) : null"
    >
      <el-tab-pane
        v-for="tab in tabs"
        :key="tab.route"
        :label="tab.label"
        :name="tab.route"
      />
      <keep-alive>
        <router-view :key="$route.fullPath" />
      </keep-alive>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'Invoices',
  data () {
    return {
      tabs: [
        { label: 'Invoicing Summaries', route: 'InvoicingSummaries' },
        { label: 'Invoicing Tools', route: 'InvoicingTools' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  font-size: 2rem;
  margin: 0 0 0.5em;
}
</style>
